export const shopConfig = {
  '七七健康好物': {
    contact: '17701660463',
    markImg: 'qq_2.jpg',
    bannerImg: 'qq_1.jpg',
    markBg: '#47bc99',
    navBg: '#47bc99'
  }, '小象生活': {
    contact: '18049964507',
    markImg: 'xx_2.jpg',
    bannerImg: 'xx_1.jpg',
    markBg: '#c0eaff',
    navBg: '#02b3ce'
  }, 'DoraFn严选': {
    contact: '15317216861',
    markImg: 'Dora_2.jpg',
    bannerImg: 'Dora_1.jpg',
    markBg: '#fff',
    navBg: '#e4027f'
  }, '美苑优选': {
    contact: '18001725637',
    markImg: 'my_2.jpg',
    bannerImg: 'my_1.jpg',
    markBg: '#040e29',
    navBg: '#040e29'
  }, '西米精品店': {
    contact: '17701655146',
    markImg: 'ximi_2.jpg',
    bannerImg: 'ximi_1.jpg',
    markBg: '#fff',
    navBg: '#e4027f'
  }, '安安美妆': {
    contact: '18018559931',
    markImg: 'aa_2.jpg',
    bannerImg: 'aa_1.jpg',
    markBg: '#fff',
    navBg: '#e4027f'
  }, 'my organics 旗舰店': {
    contact: '18016313253',
    markImg: 'myo_2.jpg',
    bannerImg: 'myo_1.jpg',
    markBg: '#fff',
    navBg: '#383838',
    navColor: '#000'
  }, 'Aesop旗舰店': {
    contact: '19921798144',
    markImg: 'Aesop_2.jpg',
    bannerImg: 'Aesop_1.jpg',
    markBg: '#fefef3',
    navBg: '#fefef3'
  }
}